<template>
  <div id="bottom" class="fontColor">
    <div class="container">
      <!-- 联系我 -->
      <!-- <div class="contact_me">
        <div class="contact_left">
          <span class="contact_title">联系我</span>
          <span class="email">yedeqin42@qq.com</span>
        </div>
        <div class="relativeClass">
          <img src="../assets/qq.png" alt="qq" class="icon" id="qq" />
          <div class="qqPopUp">
            <img src="../assets/qqQRcode.png" alt="" class="QRcodeImHeight" />
          </div>
        </div>
        <div>
          <img src="../assets/gitee.png" alt="gitee" class="icon" />
        </div>
        <div class="relativeClass">
          <img
            src="../assets/wechat.png"
            alt="wechat"
            class="icon"
            id="wechat"
          />
          <div class="wechatPopUp">
            <img
              src="../assets/wechatQRcode.png"
              alt=""
              class="QRcodeImHeight"
            />
          </div>
        </div>
      </div> -->
      <!-- 关于我 -->
      <!-- <div class="about_me">
        <span class="about_title">关于我</span>
        <span class="about_content"
          >一个喜欢写代码的博主，喜欢去探索未知领域，喜欢学习新内容，喜欢在代码的世界中遨游。目标成为一名优秀的前端开发工程师。</span
        >
      </div> -->

      <hr class="footer_hr" />

      <div id="buttomRight">
        <!-- 版权所有 备案号-->
        <div class="fontSizeSmall">
          Copyright © 2021-2022 blog.yedeqin.com All Rights Reserved.
          &nbsp;&nbsp;闽ICP备2021009610号-1
        </div>
        <!-- 转载注明 -->
        <div class="fontSizeMini" id="reprint">
          转载内容版权归作者及来源网站所有，本站原创内容转载请注明来源。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
/* reset */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#bottom {
  /* height: 200px; */
  background-color: #fbfbfb00;
}

.container {
  /* max-width: 1200px; */
  margin: 0px auto;
  height: 100%;
  padding: 16px 8px;
}

/* 联系我 */
.contact_me {
  max-width: 1200px;
  margin: 0px auto;
  margin-top: 16px;
  display: flex;
  align-items: flex-end;
}

.contact_left {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-right: 64px;
}

.contact_title {
  color: #000000;
}

.email {
  margin-top: 8px;
}

.icon {
  height: 32px;
  border: thin #5f6464 solid;
  padding: 2px;
  border-radius: 50%;
  margin-left: 16px;
}

.relativeClass {
  position: relative;
}

.QRcodeImHeight {
  height: 128px;
}

/* qq二维码 */
.qqPopUp {
  padding: 10px;
  background-color: var(--subject-color);
  border-radius: 10px;
  position: absolute;
  z-index: 2;
  right: -61px;
  top: -172px;
  display: none;
}

.qqPopUp::before {
  content: ' ';
  width: 0px;
  height: 0px;
  border-width: 15px;
  border-style: solid;
  border-top-color: var(--subject-color);
  border-right-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
  position: absolute;
  top: 148px;
  left: 58px;
}

#qq:hover + .qqPopUp {
  display: block;
}

/* 微信二维码 */
.wechatPopUp {
  padding: 10px;
  background-color: var(--subject-color);
  border-radius: 10px;
  position: absolute;
  z-index: 2;
  right: -61px;
  top: -172px;
  display: none;
}

.wechatPopUp::before {
  content: ' ';
  width: 0px;
  height: 0px;
  border-width: 15px;
  border-style: solid;
  border-top-color: var(--subject-color);
  border-right-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
  position: absolute;
  top: 148px;
  left: 58px;
}

#wechat:hover + .wechatPopUp {
  display: block;
}

/* 关于我 */
.about_me {
  max-width: 1200px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-top: 16px;
}

.about_title {
  color: #000000;
}

.about_content {
  margin-top: 8px;
}

.footer_hr {
  margin: 16px 0px;
  background-color: #e8e9e9;
  border: none;
  height: 0.5px;
}

#buttomRight {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.fontSizeMini {
  font-size: 0.7rem;
}

.fontSizeSmall {
  font-size: 0.9rem;
}

.fontColor {
  color: #5f6464;
  color: #eee;
}

#reprint {
  padding-top: 16px;
  padding-bottom: 8px;
}

@media screen and (max-width: 560px) {
  #main {
    display: none;
  }
}
</style>
