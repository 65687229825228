
import { onMounted, reactive, ref, watch } from '@vue/runtime-core'
import store from '../store/index'
import type { FormInstance } from 'element-plus'
import { LOGIN, LOGOUT, REGISTER } from '../api/api'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
export default {
  setup() {
    const router = useRouter()
    watch(
      () => store.state.navActivate,
      (newData, history) => {
        if (document.getElementById(history)) {
          const historyActivate: any = document.getElementById(history)
          historyActivate.classList.remove('activate')
        }

        if (document.getElementById(store.state.navActivate)) {
          const navActivate: any = document.getElementById(
            store.state.navActivate
          )
          navActivate.classList.add('activate')
        }
      }
    )
    onMounted(() => {
      if (document.getElementById(store.state.navActivate)) {
        const navActivate: any = document.getElementById(
          store.state.navActivate
        )
        navActivate.classList.add('activate')
      }
      is_login.value = Boolean(getCookie('is_login'))
    })
    // 手机端点击菜单
    const handleCommand = (path: string) => {
      router.push(path)
    }
    const loginFromRef = ref<FormInstance>()
    // 登录表单验证规则
    const loginFromRules = reactive({
      username: [
        {
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }
      ],
      password: [
        {
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }
      ]
    })
    // 登录弹窗状态
    const loginDialog = ref(false)
    // 打开登录谈弹窗
    const openLoginDialog = () => {
      setRemember()
      loginDialog.value = true
    }
    // 是否记住密码
    const is_remember = ref(false)
    // 登录表单
    const loginFrom = reactive({
      username: '',
      password: ''
    })
    // 登录
    const login = async (formEl: FormInstance | undefined) => {
      if (!formEl) return
      await formEl.validate((valid) => {
        if (valid) {
          LOGIN(loginFrom).then((response: any) => {
            if (response.code === 0) {
              setCookie('blogName', response.data.nickname, 1)
              setCookie(
                'avatar',
                'https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/' +
                  response.data.avatar,
                1
              )
              setCookie('is_login', 'true', 1)
              loginDialog.value = false
              is_login.value = true
              if (is_remember.value) {
                setCookie('username', loginFrom.username, 7)
                setCookie('password', loginFrom.password, 7)
              } else {
                setCookie('username', '', -1)
                setCookie('password', '', -1)
              }
              loginFrom.username = ''
              loginFrom.password = ''
              store.commit('updateIsLogin', true)
            } else if (response.code === -1) {
              ElMessage({
                showClose: true,
                message: response.msg,
                type: 'error'
              })
            }
          })
        }
      })
    }
    // 设置cookie
    function setCookie(name: string, value: string | '' | null, days: number) {
      const date = new Date() // 获取时间
      date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * days) // 保存的天数\
      window.document.cookie =
        name + '=' + value + ';path=/;expires=' + date.toUTCString()
    }
    // 获取cookie
    function getCookie(name: string) {
      var arr
      var reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
      if ((arr = document.cookie.match(reg))) {
        return arr[2]
      } else {
        return null
      }
    }
    // 页面首次访问，查询是否有记住密码
    function setRemember() {
      if (getCookie('username') != null && getCookie('password') != null) {
        loginFrom.username = String(getCookie('username'))
        loginFrom.password = String(getCookie('password'))
        is_remember.value = true
      } else {
        loginFrom.username = ''
        loginFrom.password = ''
        is_remember.value = false
      }
    }
    // 登录状态
    const is_login = ref(false)
    // 退出登录
    const logout = () => {
      LOGOUT()
      setCookie('blogName', '', -1)
      setCookie('avatar', '', -1)
      setCookie('is_login', '', -1)
      setCookie('username', '', -1)
      is_login.value = false
      store.commit('updateIsLogin', false)
    }
    const registerFromRef = ref<FormInstance>()
    // 注册验证表单
    const registerFromRules = reactive({
      username: [
        {
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }
      ],
      password: [
        {
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }
      ],
      nickname: [
        {
          required: true,
          message: '请输入昵称',
          trigger: 'blur'
        }
      ]
    })
    // 注册表单
    const registerFrom = reactive({
      username: '',
      password: '',
      nickname: '',
      email: ''
    })
    // 注册弹窗状态
    const registerDialog = ref(false)
    // 打开注册弹窗
    const openRegisterDialog = () => {
      loginDialog.value = false
      registerDialog.value = true
    }
    // 注册
    const register = (formEl: FormInstance | undefined) => {
      if (!formEl) return
      formEl.validate((valid) => {
        if (valid) {
          REGISTER(registerFrom).then((response: any) => {
            if (response.code === 0) {
              // 关闭注册弹窗
              registerDialog.value = false
              // 清空注册表单
              registerFrom.username = ''
              registerFrom.password = ''
              registerFrom.nickname = ''
              registerFrom.email = ''
              // 注册信息写入登录表单
              loginFrom.username = response.data.username
              loginFrom.password = response.data.password
              // 打开登录弹窗
              loginDialog.value = true
              ElMessage({
                showClose: true,
                message: '注册成功，请登录！',
                type: 'success'
              })
            } else if (response.code === -1) {
              ElMessage({
                showClose: true,
                message: response.msg,
                type: 'error'
              })
            }
          })
        }
      })
    }
    // 页面跳转
    const to = (url:string) => {
      router.push(url)
    }
    return {
      loginFromRef,
      loginFromRules,
      loginDialog,
      openLoginDialog,
      is_remember,
      loginFrom,
      login,
      getCookie,
      logout,
      is_login,
      registerFromRef,
      registerFromRules,
      registerFrom,
      registerDialog,
      openRegisterDialog,
      register,
      handleCommand,
      to
    }
  }
}
