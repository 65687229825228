
import NewFooter from './NewFooter.vue'
import NewHeader from './NewHeader.vue'
import store from '../store/index'
import { useRouter } from 'vue-router'
import { ref } from '@vue/runtime-core'
export default {
  components: {
    NewFooter,
    NewHeader
  },
  props: {
    width: {
      Type: Number,
      default: 1200
    }
  },
  setup() {
    const router = useRouter()
    const scrollTop = ref(0)
    // 监听滚动
    const scrolling = (e: any) => {
      scrollTop.value = e.target.scrollTop
      if (scrollTop.value > 80) {
        const newHeader = document.getElementById('new-header')
        newHeader && newHeader.classList.add('headerHidden')
      } else if (scrollTop.value < 20) {
        const newHeader = document.getElementById('new-header')
        newHeader && newHeader.classList.remove('headerHidden')
      }
    }
    // 回到顶部
    const toTop = () => {
      const main = document.getElementById('main-background')
      if (main) {
        main.scrollTop = 0
      }
    }
    const switchVersion = () => {
      if (store.state.version === '1.0') {
        store.commit('switchVersion', '2.0')
        router.push('/')
      } else {
        store.commit('switchVersion', '1.0')
        router.push('/home')
      }
    }
    return {
      scrollTop,
      scrolling,
      toTop,
      switchVersion
    }
  }
}
